import React, { lazy, Suspense, useEffect } from "react";
import { BrowserRouter, Route, NavLink, Switch, Redirect } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

function IFApplicationMenu() {
    const { application_id } = useParams();
    const stage_list = useSelector((state) => state.ifApplication.ifMasterData?.stage_list);
    const stage_data = useSelector((state) => state.ifApplication.applicationDetails?.stage_data);
    const if_application_details = useSelector((state) => state.ifApplication.applicationDetails?.if_application_details);

    useEffect(()=>{
        if(stage_list?.length && if_application_details){
            let rejected_stage = stage_list.filter(e=>e.id == if_application_details.reject_stage_id)
            if(rejected_stage?.length){
                let select_nav = document.getElementById(rejected_stage[0].stage_url)
                if(select_nav){
                    select_nav.click()
                }
            }
        }
    }, [stage_list, if_application_details])

    const isStageCompleted = (stageId) => {
        return stage_data?.completed_stages?.some((stage) => stage.stage_id === stageId);
    };

    const getStageByKey = (key) => {
        return stage_list?.find((stage) => stage.stage_key === key);
    };

    const areStagesCompleted = (stageKeys) => {
        return stageKeys.every((key) => {
            const stage = getStageByKey(key);
            return stage && isStageCompleted(stage.id);
        });
    };

    const isStageEnabled = (stageId, set_doc_name = false) => {
        const stage = stage_list?.find((s) => s.id === stageId);
        if (!stage) return false;
        
        if (stage.stage_key === "DOC_VERIFICATION") {
            if(set_doc_name){
                localStorage.setItem("doc_name", "")
            }
            return true;
        }

        switch (stage.stage_key) {
            case "UPDATE_INFO":
                return isStageCompleted(getStageByKey("DOC_VERIFICATION")?.id);

            case "REG_ADD_DOC":
            case "CREDIT_EVAL_CHECK":
            case "CREDIT_EVAL_GO_NO_GO":
            case "DVF_FORM":
            case "HOME_SURVEY":
                return isStageCompleted(getStageByKey("UPDATE_INFO")?.id);

            case "FINAL_EVAL":
                return areStagesCompleted(["REG_ADD_DOC", "CREDIT_EVAL_CHECK", "CREDIT_EVAL_GO_NO_GO", "DVF_FORM", "HOME_SURVEY"]);

            case "MEMO":
                return isStageCompleted(getStageByKey("FINAL_EVAL")?.id);

            default:
                return false;
        }
    };

    const getStageClass = (stageId) => {
        let classList = [];

        if (isStageCompleted(stageId)) {
            classList.push("completed");
        } else if (isStageEnabled(stageId)) {
            classList.push("active");
        }

        return classList.join(" ");
    };

    const getDefaultRedirectUrl = () => {
        try {
            const firstEnabledStage = stage_list?.find((stage) => isStageEnabled(stage.id));
            if (firstEnabledStage) {
                return `/if-application-detail/${application_id}/${firstEnabledStage.stage_url}`;
            }
            return `/if-application-detail/${application_id}/doc-verification`;
        } catch (error) {
            console.error("Error in getDefaultRedirectUrl:", error);
            return `/if-application-detail/${application_id}/doc-verification`;
        }
    };

    if (!stage_data || !stage_list) {
        return <div>Loading application data...</div>;
    }

    return (
        <div className="lead-detail-tabs">
            <BrowserRouter>
                <div className="detail-form-sec">
                    <div className="menu-tab-left-panel">
                        <div className="dealer-tab-left-panel nav">
                            <ul>
                                {stage_list?.map((stage) => (
                                    <li key={stage.id}>
                                        <NavLink
                                            to={`/if-application-detail/${application_id}/${stage.stage_url}`}
                                            className={getStageClass(stage.id)}
                                            id={`${stage.stage_url}`}
                                            exact
                                            onClick={(e) => {
                                                if (!isStageEnabled(stage.id, true)) {
                                                    e.preventDefault();
                                                }
                                            }}
                                        >
                                            <span className="img-type"></span>
                                            {stage.stage_name}
                                        </NavLink>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className="route-files">
                        <Suspense fallback={<div>Loading component...</div>}>
                            <Switch>
                                {stage_list?.map((stage) => (
                                    <Route
                                        key={stage.id}
                                        path={`/if-application-detail/${application_id}/${stage.stage_url}`}
                                        render={() => {
                                            const Component = lazy(() =>
                                                import(`./${stage.component_name}`).catch((error) => {
                                                    console.error(`Error loading component ${stage.component_name}:`, error);
                                                    return {
                                                        default: () => <div>Error loading {stage.stage_name} component</div>,
                                                    };
                                                })
                                            );

                                            if (isStageEnabled(stage.id)) {
                                                return <Component />;
                                            }
                                            return <Redirect to={getDefaultRedirectUrl()} />;
                                        }}
                                    />
                                ))}
                            </Switch>
                        </Suspense>
                    </div>
                </div>
            </BrowserRouter>
        </div>
    );
}

export default IFApplicationMenu;
